/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { CSSProperties, useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const VISIBLE_POSITION = -50;

export default function BackToTopButton() {
  const [buttonStyle, setButtonStyle] = useState<CSSProperties>({
    visibility: "hidden", 
  });
  
  useScrollPosition(
    ({ currPos }) => {
      const isVisible = currPos.y < VISIBLE_POSITION;
  
      const proposedStyle : CSSProperties = {
        visibility: isVisible ? "visible" : "hidden",
        transition: `all 200ms ${isVisible ? "ease-in" : "ease-out"}`,
        transform: isVisible ? "none" : "translate(0, -100%)",
      };
  
      if (JSON.stringify(proposedStyle) === JSON.stringify(buttonStyle)) return;
  
      setButtonStyle(proposedStyle);
    },
    [buttonStyle],
  );

  return (
    <a href="#" className="back-to-top" style={{ ...buttonStyle }}> 
      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z" />
      </svg>
    </a>
  );
}
