import React, { CSSProperties } from "react";
import { BaseComponentProps } from "@/types";

interface IControlProps extends BaseComponentProps {
  color: string;
  bgColor: string;
  icon: any;
  title?: string;
  description?: string;
  aosDelay?: number;
}

export default function HeaderServiceCard(props: IControlProps) {
  const { 
    color, 
    bgColor, 
    icon,
    title, 
    description, 
    aosDelay,
    children,
  } = props;

  const iconStyle = React.useMemo<CSSProperties>(() => {
    const css = { color, background: bgColor } as CSSProperties; 
    return css;
  }, [color, bgColor]);

  return (
    <div className="header-service-card" data-aos="fade-up" data-aos-duration="1000" data-aos-delay={aosDelay * 300}>
      <div className="card-icon" style={iconStyle}>
        {icon}
      </div>
      <h3 className="card-title">{title}</h3>
      <p>
        {/* {description} */}
        {children}
      </p>
    </div>
  );
}

HeaderServiceCard.defaultProps = {
  title: "Service Title",
  // description: "Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.",
  aosDelay: 0,
  // eslint-disable-next-line react/default-props-match-prop-types
  // icon: (
  //   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
  //     <rect x="2" y="2" width="20" height="8" rx="2" ry="2" />
  //     <rect x="2" y="14" width="20" height="8" rx="2" ry="2" />
  //     <line x1="6" y1="6" x2="6.01" y2="6" />
  //     <line x1="6" y1="18" x2="6.01" y2="18" />
  //   </svg>
  // ),
};
