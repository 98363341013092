import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function AboutSection() {
  return (
    <section id="about">
      <div className="about-section">
        <div className="row">
          <div className="col-md-4 col-sm-6 text-center">
            <div className="image-container" data-aos="fade-right" data-aos-duration="750">
              <StaticImage src="../images/about/about-1.jpg" alt="about" width={320} height={360} />
            </div>
          </div>
          <div className="col-md-8 col-sm-6 about-content" data-aos="fade-in" data-aos-duartion="750">
            <h3 className="title">About Us</h3>
            <h4 className="sub-title">Custom Software Development + IT Consultancy </h4>
            <p>
              We are an experienced team of software architects, developers, and IT consultants partnering with you from ideation to implementation to deliver your best solution.  
              Our team is comprised of skilled and experienced professionals each bringing a valuable and complementary skillset to the table.  
              Our objective is to come together with you to implement creative and pragmatic tools which can be successfully used by your business.
            </p>
          </div>
        </div>
      </div>
    </section> 
  );
}
