/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const VISIBLE_POSITION = -100;

export default function NavBar({ isServices = false }) { // props: BaseComponentProps) {
  const [navbarClassList, setNavbarClassList] = useState<string>("navbar-area");
  const [isWhiteLogo, setIsWhiteLogo] = useState<boolean>(true);

  useScrollPosition(
    ({ currPos }) => {
      const isVisible = currPos.y < VISIBLE_POSITION;
      const proposedClassList = isVisible ? "navbar-area sticky" : "navbar-area";
      if (proposedClassList === navbarClassList) return;
      setIsWhiteLogo(!isVisible);

      setNavbarClassList(proposedClassList);
    },
    [navbarClassList],
  );
  
  const delay = isServices ? 500 : 1250;

  return (
    <div className={navbarClassList} data-aos="fade-down" data-aos-delay={delay} data-aos-duration="1000">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="#home">
                {isWhiteLogo && <StaticImage src="../images/logos/logo-light.png" alt="Logo" height={48} objectFit="scale-down" />}
                {!isWhiteLogo && <StaticImage src="../images/logos/logo-dark.png" alt="Logo" height={48} objectFit="scale-down" />}
                {/* <span className="text">Digital Solutions</span> */}
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
              </button>

              <div
                className="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" className="navbar-nav ms-auto">
                  <li className="nav-item">
                    {isServices && <Link className="page-scroll" to="/#">Home</Link>}
                    {!isServices && <a className="page-scroll" href="#">Home</a>}
                  </li>
                  <li className="nav-item">
                    {isServices && <Link className="page-scroll" to="/#about">About Us</Link>}
                    {!isServices && <a className="page-scroll" href="#about">About Us</a>}
                  </li>
                  <li className="nav-item">
                    {isServices && <Link className="page-scroll" to="/#services">Services</Link>}
                    {!isServices && <a className="page-scroll" href="#services">Services</a>}
                  </li>
                  <li className="nav-item">
                    <a href="#contact">Contact Us</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
