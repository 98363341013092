/* eslint-disable no-nested-ternary */
import React from "react";
// import { Link } from "gatsby";
import { BaseComponentProps } from "@/types";

interface ISolutionCardProps extends BaseComponentProps {
  icon: any;
  title: string;
  text: string;
  delay?: number;
}

export default function ServiceCard(props: ISolutionCardProps) {
  const { 
    icon,
    title, 
    text, 
    delay,
    children,
  } = props;

  return (
    <div
      className="service text-center mt-30"
      data-aos="fade-left"
      data-aos-duration="1000"
      data-aos-delay={delay * 300}
    >
      <div className="service-icon">
        {icon}
      </div>
      <div className="service-content mt-30">
        <h4 className="service-title">
          {title}
          {/* <Link to="/services">{title}</Link> */}
        </h4>
        <p className="text">
          {text}
          {children}
        </p>        
      </div>
    </div>
  );
}

ServiceCard.defaultProps = {
  delay: 0,
};
