import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function ContactSection() {
  const [contactName, setContactName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    switch (id) {
      case "name":
        setContactName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setMessage(value);
  };

  const onSend = async () => {
    if (!contactName || !email) {
      setError("Please enter your name and email address");
      return;
    }

    setError("");
    const requestBody = {
      name: contactName,
      email,
      phone,
      message,
    };
    const response = await fetch("/api/contact-email", {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log("response", data);
  };

  return (
    <section id="contact">
      <div className="contact-area" data-aos="fade-in" data-aos-duration={1000}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <h3 className="title">Contact Us</h3>
              <h4 className="sub-title">Ready to start your next project?</h4>
              <p>Contact us. We look forward to working together.</p>
              <div className="row contact-info">
                <div className="col-6">
                  <h4>Address</h4>
                  <p>
                    334 West 77th Street
                    <br />
                    Suite PH
                    <br />
                    New York, NY 10024
                  </p>
                </div>
                <div className="col-6">
                  <h4>Phone &amp; Email</h4>
                  <p>+1-917-902-4648</p>
                  <p>info@digitalsolutionsllc.net</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <form id="contact-form" className="contact-form" data-aos="fade-up" data-aos-delay={350} data-aos-duration={1000}>
                { error && <div className="error">{error}</div> }
                <input id="name" type="text" placeholder="Your Name*" value={contactName} onChange={onValueChange} />
                <input id="email" type="text" placeholder="Your Email Address*" value={email} onChange={onValueChange} />
                <input id="phone" type="text" placeholder="Your Phone Number (optional)" value={phone} onChange={onValueChange} />
                <textarea id="message" rows={5} placeholder="Your Message" value={message} onChange={onMessageChange} />
                <button type="button" className="link-btn" onClick={onSend}>Send Message</button>
              </form>
            </div>
          </div>
        </div>
        <div className="contact-globe">
          <StaticImage src="../images/contact/globe.png" alt="globe" />
        </div>        
      </div>
    </section>
  );
}
