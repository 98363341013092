import React from "react";
import ServiceCard from "@/components/service-card";
import { StaticImage } from "gatsby-plugin-image";

export default function ServicesSection() {
  return (
    <section id="services">
      <div className="services-area">
        <div className="container">
          <div className="row" data-aos="fade-in" data-aos-duration={1000}>
            <div className="col col-sm-1 col-lg-2" />
            <div className="col-sm-10 col-lg-8 text-center">
              <h3 className="title">Our Services</h3>
              <h4 className="sub-title">Delivering innovative solutions and technical consulting</h4>
              {/* <p className="text">We stand behind all our services with a 100% satisfaction guarantee. We strive to deliver best-in-class solutions in better-than-expected timeframes. We aren&apos;t finished until you&apos;re happy.</p> */}
            </div>
          </div>
          <div className="row services-row">
            <div className="col col-md-4 col-sm-6">
              <ServiceCard title="Custom Software Development" text="Front-end, back-end and everything in between.  We are a full stack development shop focusing on the Microsoft platform." icon={<StaticImage src="../images/services/ethernet.svg" alt="software development" />} />
              {/* <ul>
                <li>Cloud Application Development</li>
                <li>.NET, .NET Core, React, ...</li>
              </ul>
            </ServiceCard> */}
            </div>
            <div className="col col-md-4 col-sm-6">
              <ServiceCard title="IT Services" text="From idea to technology, technology to project plan, project plan to implementation, and implementation to maintenance and support." delay={1} icon={<StaticImage src="../images/services/hub.svg" alt="IT Services" />} />
              {/* <ul>
                <li>Project Management</li>
                <li>Business Analysis</li>
              </ul>
            </ServiceCard> */}
            </div>
            <div className="col col-md-4 col-sm-6">
              <ServiceCard title="Technical Advising" text="Use our expertise and experience to your advantage.  We can help guide your business through the myriad of existing platforms and products to find the best fit for your needs." delay={2} icon={<StaticImage src="../images/services/people.svg" alt="technical advising" />} />
              {/* <ul>
                <li>Technical Analysis</li>
                <li>Architecture Reviews</li>
              </ul>
            </ServiceCard> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
