import React from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";

import "../styles/site.scss";

import BackToTopButton from "@/components/back-to-top-button";
import favicon from "@/images/favicon.png";

// import Header from "../sections/header";
import Header from "../sections/header";
import About from "../sections/about";
import Services from "../sections/services";
import Contact from "../sections/contact";

export default function Home() {  
  React.useEffect(() => {
    AOS.init();
  }, []);

  return (
    <main>
      <Helmet>        
        <title>Digital Solutions</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css?family=Quicksand:200,300,400,500,700,800&amp;display=swap"
          rel="stylesheet"
        />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Header />
      <About />
      <Services />
      <Contact />
      <BackToTopButton />
    </main>
  );
}
