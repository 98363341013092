import React from "react";
import NavBar from "../components/navbar";
import HeaderServiceCard from "../components/header-service-card";

const softwareSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="42" width="42" viewBox="0 0 24 24" fill="currentColor">
    <path d="M7.77 6.76L6.23 5.48.82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24zM7 13h2v-2H7v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52z" />
  </svg>
);

const itSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" height="42" viewBox="0 0 24 24" width="42" fill="currentColor">
    <path d="M17 16l-4-4V8.82C14.16 8.4 15 7.3 15 6c0-1.66-1.34-3-3-3S9 4.34 9 6c0 1.3.84 2.4 2 2.82V12l-4 4H3v5h5v-3.05l4-4.2 4 4.2V21h5v-5h-4z" />
  </svg>
);

const advisingSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
    <circle cx="9" cy="7" r="4" />
    <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
    <path d="M16 3.13a4 4 0 0 1 0 7.75" />
  </svg> 
);

export default function HeaderSection() {
  return (
    <header className="header-area">
      <div id="home" className="bg-cover">
        <NavBar />
        <div className="container header-hero-content">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="header-title" data-aos="fade-up" data-aos-duration={1000}>Digital Solutions</h2>
              {/* <h3 className="header-sub-title" data-aos="fade-up" data-aos-duration={1000} data-aos-delay={250}>Slogan goes here</h3> */}
              <span className="text" data-aos="fade-up" data-aos-duration={1000} data-aos-delay={250}>
                Helping you navigate the vast and ever-changing world of Information Technology to find the best &nbsp;
                <span style={{ fontWeight: 600 }}>digital solutions</span> 
                &nbsp;for your business
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <a href="#contact" className="link-btn" data-aos="fade-in" data-aos-duration={1000} data-aos-delay={750}>Learn More</a>
            </div>
          </div>
        </div>
        <div className="container header-services">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <HeaderServiceCard title="Custom Software Development" aosDelay={4} color="#651827" bgColor="#BF7987" icon={softwareSvg}>
                Front-end, back-end and everything in between.  We&apos;re a full stack development shop.
              </HeaderServiceCard>
            </div>
            <div className="col-md-4 col-sm-6">
              <HeaderServiceCard title="IT Services" aosDelay={5} color="#005146" bgColor="#3CA193" icon={itSvg}>
                From idea to plan, plan to implementation, and implementation to maintenance through support. 
              </HeaderServiceCard>
            </div>
            <div className="col-md-4 col-sm-6">
              <HeaderServiceCard title="Technology Advising" aosDelay={6} color="#296390" bgColor="#C6DEF1" icon={advisingSvg}>
                Leverage our expertise and experience.  We will help you find the best fit for your needs. 
              </HeaderServiceCard>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
